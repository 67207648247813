<template>
  <b-row class="p-2">
    <b-col
      v-for="(item, index) in dataList"
      :key="index"
      cols="6"
      lg="6"
      class="px-2 mt-2"
    >
      <div
        class="card pointer shadow"
        @click="$emit('getDetails', item.urlKey)"
      >
        <div class="product-image">
          <div>
            <template v-if="index == 0">
              <b-img-lazy
                v-bind="mainProps"
                :src="require('@/assets/images/point_counter.jpeg')"
                class="lazy-image rounded-5xem"
                alt="Image 1"
              ></b-img-lazy>
            </template>
            <template v-else-if="index == 1">
              <b-img-lazy
                v-bind="mainProps"
                :src="require('@/assets/images/point_shop.jpeg')"
                class="lazy-image rounded-5xem"
                alt="Image 1"
              ></b-img-lazy>
            </template>
            <template v-else>
              <b-img-lazy
                v-bind="mainProps"
                :src="require('@/assets/images/default-image.png')"
                class="lazy-image rounded-5xem"
                alt="Image 1"
              ></b-img-lazy>
            </template>
          </div>
        </div>
        <div class="mx-2 mt-2">
          <div class="line-clamp w-100 font-weight-bold text-13xem mb-1">
            {{ item.name }}
          </div>
          <div class="line-clamp w-100 mb-1 text-description f-xs">
            {{ item.description }}
          </div>
          <div class="line-clamp w-100 mb-1 text-valid f-xs">
            {{ item.startTime }} - {{ item.endTime }}
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    dataList: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      }
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
::v-deep .card {
  min-height: 100%;
}
.text-description {
  color: gray;
}
.text-valid {
  color: black;
}
.product-image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
.product-image > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.lazy-image {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 0.45rem 0.45rem 0px 0px;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
// .shop-card {
//   padding: 5px;
//   border: 1px solid #d8dbe0;
//   border-radius: 5px;
//   font-size: 17px;
// }
// .product-list-card {
//   font-size: var(--text-md)
//   padding: 10px;
//   padding-top: 0px;
//   border-bottom: 4px solid lightgrey;
//   width: auto;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
// }
// .product-list-card-content .product-img-top {
//   display: none;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 99;
// }
// .product-list-card-content:hover .product-img-top {
//   display: inline;
// }
// .product-list-card-content {
//   flex: 1;
//   max-height: 340px;
//   display: inline-block;
//   position: relative;
// }
// .product-img {
//   width: 100%;
//   height: 200px;
//   order: 1px solid #dbdbdb;
//   padding: 5px;
//   padding-top: 0px;
//   object-fit: cover;
// }
// .text-red {
//   color: red;
// }
// .pointer {
//   cursor: pointer;
// }
// @media only screen and (min-width: 768px) {
//   .product-list-card-content {
//     flex: 1;
//     max-height: 500px;
//     display: inline-block;
//     position: relative;
//   }
//   .product-img {
//     width: 100%;
//     height: 500px;
//     order: 1px solid #dbdbdb;
//     padding: 5px;
//     padding-top: 0px;
//     object-fit: cover;
//   }
// }
</style>
