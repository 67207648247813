<template>
  <div>
    <div class="bg-white" v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div class="bg-white pt-4 px-4 coupon-content" v-else>
      <div class="mt-2 pb-3">
        <CouponList :dataList="dataList" v-on:getDetails="getDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import CouponList from "@/components/coupon/CouponList";
import OtherLoading from "@/components/other/OtherLoading";
export default {

  components: {
    CouponList,
    OtherLoading
  },
  data() {
    return {
      isLoading: true,
      dataList: [
        {
          id: 0,
          name: "ช้อปปั๊ป รับพอยท์ 750",
          description:
            "ใช้ในเคาน์เตอร์แบรนด์ เริ่มต้นที่ 750คะแนน แลกรับบัตรกำนัลมูลค่า 100 บาท",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "black",
          backgroundColour: "white",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-A"
        },
        {
          id: 1,
          name: "ช้อปปั๊ป รับพอยท์ 500",
          description:
            "ใช้ในร้านค้าของแบรนด์ (shop) เริ่มต้นที่ 500คะแนน แลกรับบัตรกำนัลมูลค่า 50 บาท",
          startTime: "01 Jul",
          endTime: "31 Jul",
          frontColor: "white",
          backgroundColour: "",
          imageUrl: "@/assets/images/default-image.png",
          urlKey: "Coupon-B"
        }
      ]
    };
  },
  created: async function () {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
    this.$store.dispatch("setIsRegister", false);
  },
  methods: {
    getDetails(couponKey) {
      this.isLoading = true;
      this.$router.push("/coupon/user/" + couponKey);
    }
  }
};
</script>

<style lang="scss" scoped>
// .coupon-content {
//   min-height: 100vh;
// }
</style>
